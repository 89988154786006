* {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      position: relative;
}

.Container {
      top: 6rem;
      width: 500px;
      min-height: 100vh;
      margin: 0 auto;
}

@media screen and (max-width:1050px) {
      .Container {
            top: 250px;
      }
}

.Title {
      display: block;
      text-align: center;
      font-size: 40px;
}

.Alert {
      font-size: 20px;
      text-align: center;
      margin: 0.5rem 0;
      color: red;
      font-weight: 700;
}

.FormContainer {
      width: 90%;
      margin: 0 auto;
}

.label {
      display: block;
      font-size: 20px;
      font-weight: 600;
}

Input {
      width: 100%;
      display: block;
      height: 2rem;
      border-radius: 12px;
}

.Submit {
      width: 100%;
      background-color: #46cfff;
      color: white;
      font-size: 25px;
      font-weight: 500;
      cursor: pointer;
      top: 1rem;
      border-radius: 12px;
}

.Remind1 {
      display: block;
      top: 50px;
      left: 1rem;
      font-size: 20px;
      font-weight: 400;
      display: block;
}

.Remind2 {
      display: block;
      top: 85px;
      left: 1rem;
      font-size: 20px;
      font-weight: 400;
      display: block;
}