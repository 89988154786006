* {
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      position: relative;
}

/* s */

.wrapper {
      top: 80px;

}

.searchContainer {
      margin: 0 auto;
      /* width: 70%; */
      /* top: 0.5rem; */
      text-align: center;
}

.mainTitle {
      font-size: 32px;
      color: black;
      font-weight: 900;
      font-family: Open Sans, system-ui;
      margin: 30px 0;
      display: block;
}

.form {
      display: flex;
      width: 85%;
      margin: 0 auto;
}

.input {
      border: 1px solid black;
      border-radius: 50px;
      height: 50px;
      font-size: 25px;
      margin: 0 22px;
      text-align: center;
}

.icon {
      width: 43px;
      height: 43px;
      cursor: pointer;
}

.imageContainer {
      top: 40px;
      display: grid;
      width: 1500px;
      grid-template-columns: repeat(auto-fit, minmax(380px, 2fr));
}

.images {
      width: 270px;
      height: 270px;
      margin: 1rem 0;
      display: block;
      border-radius: 15px;
}