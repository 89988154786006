* {
      position: relative;
      box-sizing: border-box;
      margin: 0;
      padding: 0
}

.Container {
      top: 100px;
      width: 100%;
      display: flex;
      flex-direction: row;
}

.CardContainer {

      width: 100%;
      height: 120px;
      display: flex;
      justify-content: space-evenly;
      margin: 0.5rem 0;
      background-color: white;
}

.image {
      width: 100px;
      height: 100px;
      top: 0.5rem;
}

.button {
      width: 30px;
      height: 20px;
      font-size: 20px;
      color: white;
      background-color: #007dff;
      font-weight: 500;
      cursor: pointer;
      border-radius: 4px;
      border-color: aliceblue;
      margin: 0 1px;
}

.button2 {
      width: 100px;
      height: 30px;
      font-size: 20px;
      color: white;
      font-weight: 500;
      background-color: #007dff;
      top: 3rem;
      cursor: pointer;
      border-radius: 4px;
      border-color: aliceblue;
}

.CardContainer span {
      top: 3rem;
      font-weight: 500;
      font-size: 25px;
}

.CardContainer button {
      top: 3.2rem;
}