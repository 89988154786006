* {
      position: relative;
      box-sizing: border-box;
      margin: 0;
      padding: 0;
}

body {
      background: radial-gradient(circle, rgba(238, 174, 202, 1) 0%, rgba(148, 187, 233, 1) 100%);
}

.wrapper {
      width: 100%;
      top: 120px;
}

@media screen and (max-width:1050px) {
      .wrapper {
            top: 250px;
      }
}

.Container {
      width: 1000px;
      margin: 0 auto;
      text-align: center;
}

.MainTitle {
      font-size: 32px;
      font-weight: 700;
}

.Container p {
      font-size: 20px;
      margin: 1rem 0;
}

.input {
      display: block;
      width: 60%;
      margin: 0 auto;
      height: 30px;
      font-size: 23px;
      text-align: center;
      top: 30px 0;
}

.formTitle {
      font-size: 28px;
      display: block;
      margin-bottom: 1rem;
}

.formContent {
      width: 100%;
      display: block;
      margin-top: 5px;
      overflow: hidden;
      list-style: none;
}

.formLi {
      font-size: 18px;
      margin: 0.5rem 0;
}

.cleanUp {
      display: block;
      margin: 0 auto;
      width: 150px;
      height: 30px;
      border: 1px solid rgba(0, 0, 0, 0.7);
      background-color: #008aff;
      color: white;
      font-size: 25px;
      cursor: pointer;
      top: 25px;
      border-radius: 12px;
}